.widthTable {
  width: 100%;
  border-collapse: collapse;
}

.widthTable td {
  padding: 10px;
  border: 1px solid rgb(220,220,220);
}
.tabelModal {
  width: 100%;
  border-collapse: collapse;
}

.tabelModal td {
  padding: 10px;
  border: 1px solid rgb(220,220,220);
}